import React, {useState} from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IFormLoginController} from "views/components/forms/login/form_login.controller";
import {observer} from "mobx-react";
import {
	Button,
	CircularProgress,
	FormControl,
	FormHelperText,
	TextField,
	Typography,
} from "@mui/material";
import {Exist} from "views/components/exist/exist.component";
import {ButtonViewPassword, Form} from "views/components/forms/common/forms_common.component";
import {LinkStyled} from "views/components/links/link_styled/link_styled.component";
import styled from "@emotion/styled";
import {
	EMAIL_REGEXP_STR,
	FORM_VALIDATION_ELEMENT_CLASSNAME,
	PASSWORD_REGEXP_STR,
} from "data/constants";
import {Bindings} from "data/constants/bindings";

const Link = styled(LinkStyled)`
	font-size: 14px;
`;

const inputProps = {className: FORM_VALIDATION_ELEMENT_CLASSNAME};

export const FormLogin: React.FC = observer(() => {
	const controller = useViewController<IFormLoginController>(Bindings.FormLoginController);

	const {
		handleSubmitForm,
		handleFormChange,
		goToRegistration,
		goToForgotPassword,
		formErrors,
		error,
		isFormLocked,
		tmpUserData,
		i18n,
	} = controller;

	const labelEmail = i18n.t("login_form.label.email", "Email");
	const labelPassword = i18n.t("login_form.label.password", "Password");
	const [isPasswordVisible, setPasswordVisible] = useState(false);
	const handleToggleShowPassword = () => setPasswordVisible(!isPasswordVisible);

	const errorEmail = formErrors["email"];
	const errorPassword = formErrors["password"];

	return (
		<Form onSubmit={handleSubmitForm} onChange={handleFormChange} noValidate>
			<Typography sx={{fontWeight: 500, textAlign: "center"}}>
				{i18n.t("login_form.title", "Log in to your account")}
			</Typography>
			<FormControl>
				<TextField
					disabled={isFormLocked}
					defaultValue={tmpUserData?.email}
					label={labelEmail}
					placeholder={labelEmail}
					type="email"
					name="email"
					required
					inputProps={{
						pattern: EMAIL_REGEXP_STR,
						className: inputProps.className,
					}}
					error={Boolean(errorEmail)}
					helperText={errorEmail}
				/>
			</FormControl>
			<FormControl>
				<TextField
					disabled={isFormLocked}
					defaultValue={tmpUserData?.password}
					label={labelPassword}
					placeholder={labelPassword}
					type={isPasswordVisible ? "text" : "password"}
					name="password"
					required
					inputProps={{
						pattern: PASSWORD_REGEXP_STR,
						className: inputProps.className,
					}}
					InputProps={{
						sx: {paddingRight: 0},
						endAdornment: (
							<ButtonViewPassword
								isVisible={isPasswordVisible}
								onClick={handleToggleShowPassword}
							/>
						),
					}}
					error={Boolean(errorPassword)}
					helperText={errorPassword}
				/>
			</FormControl>
			<Typography sx={{textAlign: "right", fontWeight: 500}}>
				<Link onClick={goToForgotPassword}>
					{i18n.t("login_form.forgot_password.link", "Forgot Password?")}
				</Link>
			</Typography>
			<Exist when={error}>
				<FormHelperText error>{error}</FormHelperText>
			</Exist>
			<Button
				size="large"
				fullWidth
				variant="contained"
				disabled={isFormLocked}
				type="submit">
				<Exist when={!isFormLocked}>{i18n.t("login_form.button.login", "Login")}</Exist>
				<Exist when={isFormLocked}>
					<CircularProgress size={20} color="inherit" sx={{mr: 1.5}} />
					{i18n.t("login_form.button.login_in_progress", "Loading...")}
				</Exist>
			</Button>
			<Typography
				sx={{textAlign: "center", fontSize: 14, fontFamily: "var(--fontFamilyBase)"}}>
				{i18n.t("login_form.register.new", "New?")}{" "}
				<Link onClick={goToRegistration}>
					{i18n.t("login_form.register.link", "Register Now")}
				</Link>
			</Typography>
		</Form>
	);
});
