import React, {useState} from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import {IFormRegistrationController} from "views/components/forms/registration/form_registration.controller";
import {
	Button,
	CircularProgress,
	FormControl,
	FormHelperText,
	TextField,
	Typography,
} from "@mui/material";
import {Exist} from "views/components/exist/exist.component";
import {ButtonViewPassword, Form} from "views/components/forms/common/forms_common.component";
import {CustomField} from "views/components/forms/custom_fields/custom_field.component";
import {LinkStyled} from "views/components/links/link_styled/link_styled.component";
import styled from "@emotion/styled";
import {
	EMAIL_REGEXP_STR,
	FORM_VALIDATION_ELEMENT_CLASSNAME,
	PASSWORD_REGEXP_STR,
} from "data/constants";
import {Bindings} from "data/constants/bindings";

const Link = styled(LinkStyled)`
	font-size: 14px;
`;

const inputProps = {minLength: 2, maxLength: 100, className: FORM_VALIDATION_ELEMENT_CLASSNAME};

export const FromRegistration: React.FC = observer(() => {
	const controller = useViewController<IFormRegistrationController>(
		Bindings.FormRegistrationController
	);

	const {
		handleSubmitForm,
		handleFormChange,
		handleInputFieldChange,
		handleUsernameFieldChange,
		handleCustomFieldChange,
		goToLogin,
		customFields,
		error,
		formErrors,
		isFormLocked,
		tmpUserData,
		i18n,
		formValidationHelper,
	} = controller;

	const labelUsername = i18n.t("registration_form.label.username", "Display name");
	const labelEmail = i18n.t("registration_form.label.email", "Email");
	const labelPassword = i18n.t("registration_form.label.password", "Password");
	const labelConfirmPassword = i18n.t(
		"registration_form.label.confirm_password",
		"Confirm Password"
	);
	const labelFirstName = i18n.t("registration_form.label.first_name", "First name");
	const labelLastName = i18n.t("registration_form.label.last_name", "Last name");

	const [isPasswordVisible, setPasswordVisible] = useState(false);
	const handleToggleShowPassword = () => setPasswordVisible(!isPasswordVisible);

	const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
	const handleToggleShowConfirmPassword = () =>
		setConfirmPasswordVisible(!isConfirmPasswordVisible);

	const errorUserName = formErrors["username"];
	const errorEmail = formErrors["email"];
	const errorPassword = formErrors["password"];
	const errorConfirmPassword = formErrors["confirmPassword"];
	const errorFirstName = formErrors["firstName"];
	const errorLastName = formErrors["lastName"];

	return (
		<Form onSubmit={handleSubmitForm} onChange={handleFormChange} noValidate autoComplete="off">
			<Typography sx={{fontWeight: 500, textAlign: "center"}}>
				{i18n.t("registration_form.title", "Register an account")}
			</Typography>
			<FormControl>
				<TextField
					disabled={isFormLocked}
					onInput={handleInputFieldChange}
					defaultValue={tmpUserData?.email}
					label={labelEmail}
					placeholder={labelEmail}
					type="email"
					name="email"
					required
					inputProps={{
						pattern: EMAIL_REGEXP_STR,
						className: inputProps.className,
					}}
					error={Boolean(errorEmail)}
					helperText={errorEmail}
				/>
			</FormControl>
			<FormControl>
				<TextField
					autoComplete="new-password"
					disabled={isFormLocked}
					onInput={handleInputFieldChange}
					defaultValue={tmpUserData?.password}
					label={labelPassword}
					placeholder={labelPassword}
					type={isPasswordVisible ? "text" : "password"}
					name="password"
					required
					inputProps={{
						pattern: PASSWORD_REGEXP_STR,
						className: inputProps.className,
					}}
					InputProps={{
						sx: {paddingRight: 0},
						endAdornment: (
							<ButtonViewPassword
								isVisible={isPasswordVisible}
								onClick={handleToggleShowPassword}
							/>
						),
					}}
					error={Boolean(errorPassword)}
					helperText={errorPassword || formValidationHelper.errors.byFieldName.password}
				/>
			</FormControl>
			<FormControl>
				<TextField
					autoComplete="new-password"
					disabled={isFormLocked}
					onInput={handleInputFieldChange}
					label={labelConfirmPassword}
					placeholder={labelConfirmPassword}
					type={isConfirmPasswordVisible ? "text" : "password"}
					name="confirmPassword"
					required
					inputProps={{
						pattern: PASSWORD_REGEXP_STR,
						className: inputProps.className,
					}}
					InputProps={{
						sx: {paddingRight: 0},
						endAdornment: (
							<ButtonViewPassword
								isVisible={isConfirmPasswordVisible}
								onClick={handleToggleShowConfirmPassword}
							/>
						),
					}}
					error={Boolean(errorConfirmPassword)}
					helperText={errorConfirmPassword}
				/>
			</FormControl>
			<FormControl>
				<TextField
					disabled={isFormLocked}
					onInput={handleInputFieldChange}
					defaultValue={tmpUserData?.firstName}
					label={labelFirstName}
					placeholder={labelFirstName}
					type="text"
					name="firstName"
					inputProps={inputProps}
					required
					error={Boolean(errorFirstName)}
					helperText={errorFirstName}
				/>
			</FormControl>
			<FormControl>
				<TextField
					disabled={isFormLocked}
					onInput={handleInputFieldChange}
					defaultValue={tmpUserData?.lastName}
					label={labelLastName}
					placeholder={labelLastName}
					type="text"
					name="lastName"
					inputProps={inputProps}
					required
					error={Boolean(errorLastName)}
					helperText={errorLastName}
				/>
			</FormControl>
			<FormControl>
				<TextField
					disabled={isFormLocked}
					onInput={handleUsernameFieldChange}
					defaultValue={tmpUserData?.username}
					label={labelUsername}
					placeholder={labelUsername}
					type="text"
					name="username"
					inputProps={inputProps}
					required
					error={Boolean(errorUserName)}
					helperText={errorUserName}
				/>
			</FormControl>
			{customFields?.map((field) => {
				const value = tmpUserData?.[field.code as keyof typeof tmpUserData];

				return (
					<CustomField
						{...field}
						key={field.id}
						disabled={isFormLocked}
						onChange={handleCustomFieldChange}
						error={formErrors[field.code]}
						defaultValue={value ?? field.defaultValue}
					/>
				);
			})}
			<Exist when={error}>
				<FormHelperText error>{error}</FormHelperText>
			</Exist>
			<Button
				size="large"
				fullWidth
				variant="contained"
				disabled={isFormLocked}
				type="submit">
				<Exist when={!isFormLocked}>
					{i18n.t("registration_form.button.register", "Register")}
				</Exist>
				<Exist when={isFormLocked}>
					<CircularProgress size={20} color="inherit" sx={{mr: 1.5}} />
					{i18n.t("registration_form.button.register_in_progress", "Loading...")}
				</Exist>
			</Button>
			<Typography
				sx={{textAlign: "center", fontSize: 14, fontFamily: "var(--fontFamilyBase)"}}>
				{i18n.t("registration_form.login.have_account_copy", "Already have an account?")}{" "}
				<Link onClick={goToLogin}>{i18n.t("registration_form.login.link", "Log In")}</Link>
			</Typography>
		</Form>
	);
});
