import styled from "@emotion/styled";
import {css} from "@mui/material";
import background from "assets/img/background_home.jpg";
import backgroundMobile from "assets/img/backdround_home_mobile.jpg";

export const Container = styled.div`
	max-width: 1264px;
	width: 100%;
	padding: 0 32px;
	margin: 0 auto;
	box-sizing: border-box;

	@media (max-width: 870px) {
		padding-left: 20px;
		padding-right: 20px;
	}
`;

interface IPageHeader {
	size?: "small";
}

export const PageHeader = styled.section<IPageHeader>`
	background: url(${background}) no-repeat top center / cover;
	width: 100%;
	color: #fff;
	min-height: 230px;
	display: flex;
	align-items: center;
	margin-bottom: -30px;
	padding-top: 20px;
	padding-bottom: 20px;

	@media (max-width: 870px) {
		min-height: 135px;
		margin-bottom: -10px;
		background-image: url(${backgroundMobile});
	}

	${(props) =>
		props.size === "small"
			? css`
					min-height: 64px;
					margin-bottom: 0;
					padding: 20px;
					font-size: 24px;
					font-weight: 700;

					@media (max-width: 870px) {
						margin-bottom: 0;
						min-height: 64px;
					}
			  `
			: ""};
`;

export const PageHeaderContainer = styled(Container)`
	@media (max-width: 870px) {
		padding-left: 0;
		padding-right: 0;
	}
`;

export const Sidebar = styled.aside`
	width: 100%;
	max-width: 300px;
	display: flex;
	flex-flow: column;
	gap: 24px;
`;
