import en_logo_black from "assets/img/game_logo_black_en.png";
import fr_logo_black from "assets/img/game_logo_black_fr.png";
import fr_logo_white from "assets/img/game_logo_white_fr.png";
import en_logo_white from "assets/img/game_logo_white_en.png";
import en_landing_image from "assets/img/landing_image.png";
import fr_landing_image from "assets/img/landing_image_fr.png";

const BLACK_LOGO = {
	en: en_logo_black,
	fr: fr_logo_black,
};

const WHITE_LOGO = {
	en: en_logo_white,
	fr: fr_logo_white,
};

export const getLogoByLocale = (locale: string | null, type: "black" | "white" = "white") => {
	const logoSet = type === "white" ? WHITE_LOGO : BLACK_LOGO;
	const localeName = locale?.includes("fr") ? "fr" : "en";
	return logoSet[localeName];
};

const LANDING_IMAGE = {
	en: en_landing_image,
	fr: fr_landing_image,
};

export const getLadingImageByLocale = (locale: string | null) => {
	const localeName = locale?.includes("fr") ? "fr" : "en";
	return LANDING_IMAGE[localeName];
};
