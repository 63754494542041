import styled from "@emotion/styled";
import {Button} from "@mui/material";

import React from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IAuthController} from "views/controllers/auth/auth.controller";
import {Bindings} from "data/constants/bindings";
import {ILocalizationController} from "views/controllers/localization/localization.controller";

export const ButtonLogout: React.FC = () => {
	const {logout} = useViewController<IAuthController>(Bindings.AuthController);
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);

	return (
		<Button
			sx={{fontFamily: "var(--fontFamilySecond)", fontWeight: 700, fontSize: 16}}
			variant="outlined"
			onClick={() => void logout()}>
			{i18n.t("button.logout.text", "Logout")}
		</Button>
	);
};

const ButtonPrimaryBase = styled(Button)`
	min-width: 120px;
	font-weight: 700;
	font-size: 16px;
	font-family: var(--fontFamilySecond);
`;

ButtonPrimaryBase.defaultProps = {
	variant: "contained",
};

/**
 * Type casting is required because of MUI issue
 * https://github.com/mui/material-ui/issues/13921#issuecomment-484133463
 */
export const ButtonPrimary = ButtonPrimaryBase as typeof Button;

export const ButtonPure = styled.button`
	padding: 0;
	margin: 0;
	outline: none;
	background: none;
	border: none;
	cursor: pointer;
`;

export const ModalCloseButton = styled(ButtonPure)`
	position: absolute;
	right: 20px;
	top: 20px;
	color: var(--textColor3);
`;

export const LoadMoreButton = styled(ButtonPrimary)`
	width: 100%;
	padding: 20px;
	max-width: 100%;
	min-width: auto;
	font-family: var(--fontFamilyBase);
	color: var(--textColor3);
	font-weight: 400;
`;
