import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, computed, makeAutoObservable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import GeniusSportsMessageBus, {
	IGeniusSportsMessageBus,
} from "@geniussports/gsm-web-core-library/lib/genius-sports-message-bus";
import {QuestionUtils} from "data/utils/question_utils";
import QuestionCard from "@geniussports/fep-widgets/dist/esm/QuestionCardComponent";
import {Bindings} from "data/constants/bindings";
import type {IQuestionWithAnswers} from "data/stores/gameplay/gameplay.store";

interface IControllerProps {
	question: IQuestionWithAnswers;
}

type TQuestionProps = Parameters<typeof QuestionCard>[0];
type TAnswerCardProps = TQuestionProps["answerCard"];
type TQuestionHeaderProps = Pick<TQuestionProps["header"], "status" | "statusMessage">;

const mapLayoutToWidget = {
	Horizontal: "horizontal",
	Vertical: "vertical",
} as const;

const mapAnswerTypeToWidget = {
	Multichoice: "multipleorbinary",
	// Prediction: "",
	// Range: "",
} as const;

const mapSelectionsAmountToWidget = {
	Multichoice: 1,
	// Prediction: 0,
	// Range: 0,
} as const;

type TLayout = keyof typeof mapLayoutToWidget;
type TAnswer = keyof typeof mapAnswerTypeToWidget;

export interface IContestQuestionCardController extends ViewController<IControllerProps> {
	readonly messageBus: IGeniusSportsMessageBus;
	get i18n(): ILocalizationStore;
	get questionHeaderStatusProps(): TQuestionHeaderProps;
	get questionLayout(): string;
	get questionAnswerType(): TAnswerCardProps["answerType"];
	get questionAllowedSelections(): number;
	updateQuestion: (question: IQuestionWithAnswers) => void;
}

@injectable()
export class ContestQuestionCardController implements IContestQuestionCardController {
	question?: IQuestionWithAnswers;

	@computed get questionLayout() {
		return mapLayoutToWidget[this.question?.layout.name as TLayout];
	}

	@computed get questionAnswerType() {
		return mapAnswerTypeToWidget[this.question?.type.logicType as TAnswer];
	}

	@computed get questionAllowedSelections() {
		return mapSelectionsAmountToWidget[this.question?.type.logicType as TAnswer];
	}

	@computed get questionHeaderStatusProps() {
		const question = this.question;

		if (!question) return {};

		const i18n = this.i18n;
		const msgLocked = i18n.t("question.header.locked.message", "Locked");
		const msgCongratulations = i18n.t(
			"question.header.complete.congratulations",
			"Congratulations!"
		);
		const msgIncorrect = i18n.t("question.header.complete.wrong_answer", "Nice try!");
		const msgNoAnswer = i18n.t(
			"question.header.complete.no_answer",
			"Oops, you missed this one!"
		);
		const msgNoPoints = i18n.t("question.header.complete.no_pts", "You don’t receive points.");
		const msgWonPts = i18n.t("question.header.complete.won_pts", "You've won {{ X }}pts.", {
			X: QuestionUtils.getPointsValue(question),
		});

		let status, statusMessage;

		if (QuestionUtils.isLocked(question)) {
			status = "disabled" as const;
			statusMessage = msgLocked;
		}

		if (QuestionUtils.isComplete(question)) {
			if (QuestionUtils.hasCorrectAnswer(question)) {
				status = "correct" as const;
				statusMessage = `${msgCongratulations}|${msgWonPts}`;
			} else {
				const msgIncorrectVariant = QuestionUtils.hasIncorrectAnswer(question)
					? msgIncorrect
					: msgNoAnswer;

				status = "incorrect" as const;
				statusMessage = `${msgIncorrectVariant}|${msgNoPoints}`;
			}
		}

		return {status, statusMessage};
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(GeniusSportsMessageBus) readonly messageBus: IGeniusSportsMessageBus
	) {
		makeAutoObservable(this);
	}

	@action updateQuestion = (question: IQuestionWithAnswers) => {
		this.question = question;
	};

	init({question}: IControllerProps) {
		this.updateQuestion(question);
	}

	dispose(): void {
		return;
	}
}
