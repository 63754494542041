import {first, isEqual, property} from "lodash";
import QuestionCard from "@geniussports/fep-widgets/dist/esm/QuestionCardComponent";
import type {IQuestionWithAnswers} from "data/stores/gameplay/gameplay.store";

type TOptionStatus = Parameters<typeof QuestionCard>[0]["answerCard"]["answers"][number]["status"];
type TQuestionStatusKey = "SCHEDULED" | "LOCKED" | "COMPLETE";
type TOptionStatusKey =
	| "DEFAULT"
	| "CORRECT"
	| "WAS_CORRECT"
	| "INCORRECT"
	| "SELECTED"
	| "DISABLED";

const QuestionsStatus: Record<TQuestionStatusKey, QuestionStatus> = {
	SCHEDULED: "Scheduled",
	LOCKED: "Locked",
	COMPLETE: "Completed",
};

const OptionStatus: Record<TOptionStatusKey, TOptionStatus> = {
	DEFAULT: "default",
	CORRECT: "correct",
	WAS_CORRECT: "wascorrect",
	INCORRECT: "incorrect",
	SELECTED: "selected",
	DISABLED: "disabled",
};

const {SCHEDULED, LOCKED, COMPLETE} = QuestionsStatus;

export abstract class QuestionUtils {
	static questionStatus = QuestionsStatus;
	static optionStatus = OptionStatus;

	static isScheduled = ({status}: IQuestionFragment) => isEqual(SCHEDULED, status);

	static isLocked = ({status}: IQuestionFragment) => isEqual(LOCKED, status);

	static isComplete = ({status}: IQuestionFragment) => isEqual(COMPLETE, status);

	static getPointsValue = ({scoringRules, contest}: IQuestionFragment) => {
		const points = first(scoringRules)?.points || first(contest.scoringRules)?.points;
		return points ? String(points) : undefined;
	};

	static getOptionStatus = (question: IQuestionWithAnswers, optionID: number) => {
		const answersIds = question.answers.map<number>(property("option.id"));
		const correctOptionIds = question.correctOptions.map<number>(property("option.id"));
		const isAnswered = answersIds.includes(optionID);
		const isCorrect = correctOptionIds.includes(optionID);

		if (QuestionUtils.isComplete(question)) {
			return QuestionUtils.getOptionStatusForCompleteState(isAnswered, isCorrect);
		}

		if (QuestionUtils.isLocked(question)) {
			return QuestionUtils.getOptionStatusForLockedState(isAnswered);
		}

		return QuestionUtils.getOptionStatusForScheduledState(isAnswered);
	};

	static hasCorrectAnswer(question: IQuestionWithAnswers) {
		return question.options.some(({id}) =>
			isEqual(QuestionUtils.getOptionStatus(question, id), OptionStatus.CORRECT)
		);
	}

	static hasIncorrectAnswer(question: IQuestionWithAnswers) {
		return question.options.some(({id}) =>
			isEqual(QuestionUtils.getOptionStatus(question, id), OptionStatus.INCORRECT)
		);
	}

	private static getOptionStatusForCompleteState(isAnswered: boolean, isCorrect: boolean) {
		if (isAnswered) {
			return isCorrect ? OptionStatus.CORRECT : OptionStatus.INCORRECT;
		}

		return isCorrect ? OptionStatus.WAS_CORRECT : OptionStatus.DISABLED;
	}

	private static getOptionStatusForLockedState(isAnswered: boolean) {
		return isAnswered ? OptionStatus.SELECTED : OptionStatus.DISABLED;
	}

	private static getOptionStatusForScheduledState(isAnswered: boolean) {
		return isAnswered ? OptionStatus.SELECTED : OptionStatus.DEFAULT;
	}
}
