import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, computed, makeAutoObservable, observable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IGameplayStore} from "data/stores/gameplay/gameplay.store";
import {Bindings} from "data/constants/bindings";
import {ContestUtils} from "data/utils/contest_utils";
import {DateTime} from "luxon";
import {find} from "lodash";
import {DATE_FORMAT_BY_LOCALE} from "data/constants";

interface IControllerProps {
	contest: IContestFragment;
}

export interface IContestBannerController extends ViewController<IControllerProps> {
	get i18n(): ILocalizationStore;
	get hasContest(): boolean;
	get isComplete(): boolean;
	get isLocked(): boolean;
	get isOpen(): boolean;
	get isComingSoon(): boolean;
	get contestURL(): string;
	get isDateVisible(): boolean;
	get isTimerVisible(): boolean;
	get contestButtonCopy(): string;
	get lockDateString(): string;
	updateContest: (contest: IContestFragment) => void;
	setContestComplete: () => void;
}

@injectable()
export class ContestBannerController implements IContestBannerController {
	@observable private _contest?: IContestFragment;

	private get hasQuestions() {
		return Boolean(this._contest?.hasQuestions);
	}

	get hasContest() {
		return Boolean(this._contest);
	}

	@computed get isComplete() {
		if (!this._contest) return false;
		return ContestUtils.isComplete(this._contest);
	}

	@computed get isLocked() {
		if (!this._contest) return false;
		return ContestUtils.isActive(this._contest);
	}

	@computed get isOpen() {
		if (!this._contest) return false;
		return ContestUtils.isScheduled(this._contest) && this.hasQuestions;
	}

	@computed get isComingSoon() {
		if (!this._contest) return false;
		return ContestUtils.isScheduled(this._contest) && !this.hasQuestions;
	}

	@computed get contestButtonCopy() {
		if (this.isOpen) return this.i18n.t("contest.link.play_now", "Make picks");
		if (this.isComingSoon) return this.i18n.t("contest.link.coming_soon", "Coming soon");
		if (this.isLocked) return this.i18n.t("contest.link.view_picks", "View picks");
		if (this.isComplete) return this.i18n.t("contest.link.review", "Review");
		return " ";
	}

	@computed get contestURL() {
		if (!this._contest) return "";

		const id = this._contest.id;
		return this.isOpen ? `/contest/${id}` : `/contest/${id}/result`;
	}

	@computed get isDateVisible() {
		if (!this._contest) return false;
		return ContestUtils.isLocksAfterOneDay(this._contest);
	}

	@computed get isTimerVisible() {
		if (!this._contest) return false;
		return ContestUtils.isLocksInOneDay(this._contest);
	}

	@computed get lockDateString() {
		if (!this._contest) return "";

		const locale = this.i18n.locale!;

		const timeFormat =
			find(DATE_FORMAT_BY_LOCALE, (_, lang) => locale.includes(lang)) ||
			DATE_FORMAT_BY_LOCALE.en;

		const dt = DateTime.fromISO(this._contest.dateStart, {locale});
		return dt.toFormat(timeFormat);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.GameplayStore) private readonly _gameplayStore: IGameplayStore
	) {
		makeAutoObservable(this);
	}

	@action setContestComplete = () => {
		this._gameplayStore.setContestComplete(this._contest?.id ?? 0);
	};

	@action updateContest = (contest: IContestFragment) => {
		this._contest = contest;
	};

	@action init(param: IControllerProps): void {
		this.updateContest(param.contest);
	}

	dispose(): void {
		return;
	}
}
